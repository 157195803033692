import { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/home/scrollToTop";

import "./App.css";
import NavBar from "./components/home/Navbar";
import Footer from "./components/home/Footer";
import Home from "./components/home/Home";
import Challenges from "./components/challenges/Challenges";
import WorkPackages from "./components/project/Workpackages";
import Outcomes from "./components/project/Outcomes";
import Consortium from "./components/project/Consortium";
import Events from "./components/Events_News/Events";
import News from "./components/Events_News/News";
import Press from "./components/Resources/Press/Press";
import Leaflets from "./components/Resources/Leaflets/Leaflets";
import Publications from "./components/Resources/Publications/Publications";
import PressDe from "./components/Resources/Press/Press-de";
import PageNotFound from "./components/home/PageNotFOund";
import Contact from "./components/Contact/Contacts";
import Aaif from "./components/project/consortium/Aaif";
import Exys from "./components/project/consortium/Exys";
import Sph from "./components/project/consortium/Sph";
import Citst from "./components/project/consortium/Citst";
import Inrca from "./components/project/consortium/Inrca";
import Oct from "./components/project/consortium/Oct";
import Okey from "./components/project/consortium/Okey";
import Wut from "./components/project/consortium/Wut";
import Eurag from "./components/project/consortium/Eurag";
import PressRo from "./components/Resources/Press/Press-ro";
import PressIt from "./components/Resources/Press/Press-it";
import PressPol from "./components/Resources/Press/Press-pol";
import PressFr from "./components/Resources/Press/Press-fr";

import Blogs from "./components/Resources/Blogs/Blogs";
import Blog1 from "./components/Resources/Blogs/Blog1";
import Blog2 from "./components/Resources/Blogs/Blog2";
import Blog3 from "./components/Resources/Blogs/Blog3";
import Blog4 from "./components/Resources/Blogs/Blog4";
import Blog5 from "./components/Resources/Blogs/Blog5";
import Blog6 from "./components/Resources/Blogs/Blog6";
import Blog7 from "./components/Resources/Blogs/Blog7";
import Blog8 from "./components/Resources/Blogs/Blog8";
import Blog9 from "./components/Resources/Blogs/Blog9";
import Blog10 from "./components/Resources/Blogs/Blog10";
import Blog11 from "./components/Resources/Blogs/Blog11";
import Blog13 from "./components/Resources/Blogs/Blog13";
import Blog12 from "./components/Resources/Blogs/Blog12";
import Blog14 from "./components/Resources/Blogs/Blog14";
import Blog15 from "./components/Resources/Blogs/Blog15";
import Blog16 from "./components/Resources/Blogs/Blog16";
import Blog17 from "./components/Resources/Blogs/Blog17";
import Blog18 from "./components/Resources/Blogs/Blog18";
import Blog19 from "./components/Resources/Blogs/Blog19";
import Blog20 from "./components/Resources/Blogs/Blog20";
import Blog21 from "./components/Resources/Blogs/Blog21";
import Blog22 from "./components/Resources/Blogs/Blog22";


function App() {
  return (
    <div className="App">
      <NavBar />
      <main>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/challenges" element={<Challenges />} />
          <Route path="/work-packages" element={<WorkPackages />} />
          <Route path="/outcomes" element={<Outcomes />} />
          <Route path="/consortium" element={<Consortium />} />
          <Route path="/aaif" element={<Aaif />} />
          <Route path="/exys" element={<Exys />} />
          <Route path="/eurag" element={<Eurag />} />
          <Route path="/sph" element={<Sph />} />
          <Route path="/citst" element={<Citst />} />
          <Route path="/inrca" element={<Inrca />} />
          <Route path="/oct" element={<Oct />} />
          <Route path="/okey" element={<Okey />} />
          <Route path="/wut" element={<Wut />} />
          <Route path="/events" element={<Events />} />
          <Route path="/news" element={<News />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog1" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/blog4" element={<Blog4 />} />
          <Route path="/blog5" element={<Blog5 />} />
          <Route path="/blog6" element={<Blog6 />} />
          <Route path="/blog7" element={<Blog7 />} />
          <Route path="/blog8" element={<Blog8 />} />
          <Route path="/blog9" element={<Blog9 />} />
          <Route path="/blog10" element={<Blog10 />} />
          <Route path="/blog11" element={<Blog11 />} />
          <Route path="/blog13" element={<Blog13 />} />
          <Route path="/blog12" element={<Blog12 />} />
          <Route path="/blog14" element={<Blog14 />} />
          <Route path="/blog15" element={<Blog15 />} />
          <Route path="/blog16" element={<Blog16 />} />
          <Route path="/blog17" element={<Blog17 />} />
          <Route path="/blog18" element={<Blog18 />} />
          <Route path="/blog19" element={<Blog19 />} />
          <Route path="/blog20" element={<Blog20 />} />
          <Route path="/blog21" element={<Blog21 />} />
          <Route path="/blog22" element={<Blog22 />} />

          <Route path="/press" element={<Press />} />
          <Route path="/press-de" element={<PressDe />} />
          <Route path="/press-ro" element={<PressRo />} />
          <Route path="/press-it" element={<PressIt />} />
          <Route path="/press-pol" element={<PressPol />} />
          <Route path="/press-fr" element={<PressFr />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/leaflets" element={<Leaflets />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
