import "./News.css";
const News = () => {
  // Function will execute on click of button
  const onButtonClick = (pdf, event) => {
     
    // using Java Script method to get PDF file
    fetch(pdf.msg).then((response) => {
        response.blob().then((blob) => {
         
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = pdf.title;
            alink.click();
        });
    });
  };
  return (
    <div className="container news">
      <div className="row" id="main-title">
        <h1>News</h1>
      </div>
      <div className="col">
        <h5>2024</h5>                    
      </div>
      <table width="100%">
        <tbody>
          <tr>
            <th width="100%">
              The 2nd Mid-Term Review Meeting with the AAL Central Management Unit (CMU) Reviewers
              took place on May 23, starting at 10 a.m.             
            </th>                   
            <th>
              <a href="https://www.careup.care/" target="_self" rel="noreferrer">
                <img src="../static/img/CAREUP.png" alt="CAREUP logo" width="180px" height="30px"></img>
              </a>              
            </th>
          </tr>
          <tr><br/></tr>
        </tbody>
      </table>
      <br />
      <div className="col">
        <h5>2023</h5>                    
      </div>
      <table>
        <tbody>
        <tr>
          <th>
            24 August -  The Mid-term Review meeting, has taken place in Milan, Italy. Themes discussed: 
            Progress of the project, Outputs & Inputs, gaps between the progress made, what the project is 
            offering and the AAL objectives, recommendations, way ahead and next steps.
          </th>          
          <th>
            <img src="../static/img/art5.jpg" alt="MTR" width="180px" height="120px"></img>            
          </th>
        </tr>
        <tr><br/></tr>
        <tr>
          <th>
            12 July - 46th International Conference on Telecommunications and Signal Processing (TSP), 
            Virtual Conference. Roxana Elena Stiuca and Irina Mocanu of CITST presented &nbsp;
            <a href="https://ieeexplore.ieee.org/document/10197710" target="_blanck" rel="noreferrer">
              "Character Animation using LSTM Networks".
            </a>
            &nbsp;&nbsp;
            <a href="../static/publications/TSP_2023.pdf" target="_blanck" rel="noreferrer">
                  <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i>
            </a>                
          </th>
          <th>
            <a href="https://www.aconf.org/conf_191139.2023_46th_International_Conference_on_Telecommunications_and_Signal_Processing_" target="_blanck" rel="noreferrer">
            <img src="../static/img/LSTM.png" alt="LSTM" width="180px" height="120px"></img>
            </a>               
          </th>
        </tr>
        <tr>
          <th>
            1 July - Publication on MDPI's Sustainability journal, Volume 15, Issue 13, of the article &nbsp;
            <a href="https://www.mdpi.com/2071-1050/15/13/10330" target="_blanck" rel="noreferrer">
              "How Technology-Based Interventions Can Sustain Ageing Well in the New Decade through the User-Driven Approach".
            </a>, written by the CAREUP consortium partners.
            &nbsp;&nbsp;
            <a href="../static/publications/sustainability-15-10330.pdf" target="_blanck" rel="noreferrer">
                  <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i>
            </a>
          </th>
          <th>
            <a href="https://www.mdpi.com/2071-1050/15/13" target="_blanck" rel="noreferrer">
            <img src="../static/img/LSTM_chart.png" alt="LSTM_chart" width="180px" height="120px"></img>
            </a>               
          </th>
        </tr>
        <tr>
          <th>
          24 May - 24th International Conference on Control Systems and Computer Science (CSCS24), Bucharest, 
          Romania. and AIHM 2023. Irina MOCANU, Oana CRAMARIUC and Dorin Ionut STANCIU of CITST presented &nbsp;
          <a href="https://ieeexplore.ieee.org/document/10214742" target="_blanck" rel="noreferrer">
              "An Intelligent Application for Improving Health Conditions through Gamification".
          </a>
          &nbsp;&nbsp;
          <a href="../static/publications/CSCS24_2023.pdf" target="_blanck" rel="noreferrer">
                <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i>
          </a>        
          </th>
          <th>
            <a href="https://cscs24.hpc.pub.ro/" target="_blanck" rel="noreferrer">
            <img src="../static/img/art4.png" alt="Games" width="180px" height="240px"></img>
            </a>               
          </th>
        </tr>
        <tr>
          <th>
            28 April - eLearning and Software for Education (eLSE) Conference, Bucharest, Romania. Irina MOCANU, 
            Alex IMAD AWADA and Oana CRAMARIUC of CITST presented "A System for Engaging Older Adults in Maintaining 
            an Active and Healthy Life Through Games". &nbsp;             
              {/* <img src="../static/img/pdf.png" alt="MTR" width="32px" height="32px" 
              onClick={(event) => onButtonClick({msg: "../static/publications/Else_2023.pdf", title: "Else_20203.pdf"}, event)}></img> */}
              <a href="../static/publications/Else_2023.pdf" target="_blanck" rel="noreferrer">
                <i class="fa fa-file-pdf fa-2x" id ="pdf-icon" aria-hidden="true"></i>
              </a>
          </th>
          <th>
            <a href="https://www.elseconference.eu/" target="_blanck" rel="noreferrer">
              <img src="../static/img/eLSE.jpg" alt="Games" width="180px" height="200px"></img>
            </a>             
          </th>
        </tr>
        </tbody>
      </table>
      <br />
      <div className="col">
        <h5>2022</h5>                    
      </div>
      <table>
        <tbody>
          <tr>
            <th >
              11 August - End-user Organization Meeting, Skype. End-user partners EURAG INRCA, AAIF, 
              discussed about WP1 activities so far, in particular about analysis of end-user goals and 
              challenges, the chosen research method for different users, Interviews with primary 
              end-users (seniors) & Protocol Questionnaire with secondary end-users (caregivers) 
              & Protocol, and next planned activities in collaboration with all partners.
            </th>
            <th>
              <img src="../static/img/art12.jpg" alt="EUO" width="180px" height="100px" ></img>
            </th>
          </tr>
          <tr>
            <th >
              12 May - CAREUP Kick-Off Meeting. Integrated Care Platform Based on the Monitoring of 
              Older Individual Intrinsic Capacity for Inclusive Health (CAREUP).
            </th>
            <th>
            <a href="https://www.careup.care/" target="_self" rel="noreferrer">
              <img src="../static/img/CAREUP.png" alt="CAREUP logo" width="180px" height="30px"></img>
            </a>              
            </th>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  );
};

export default News;
